import React from 'react'
import { Container, Row } from 'reactstrap'
import { StaticQuery, graphql } from "gatsby"

import PageTemplate from '../../components/pageTemplate'
import Service from '../../components/service'

const header = {
    title: 'プログラミングスクール',
    subTitle: 'BeCrazyプログラミングスクール'
}

const items = [
    {
        title: '未経験者対応',
        content: '未経験者でも対応可能。PCの扱い方や、プログラミングを始めるためのツールから学習できるカリキュラム'
    },
    {
        title: 'オンライン・オフライン対応',
        content: 'インターネット通話でのオンラインサポート・働いている方に合わせた夜開講オフラインサポート'
    },
    {
        title: '講師は現役ITエンジニア',
        content: '講師は現役ITエンジニアが担当し、実務レベルで必要なアドバイスなどを聞くことが可能'
    }
]

let ProgrammingSchool = () => (
    <PageTemplate title="サービス">
        <Container className="py-5">
            <Row>
                <ProgrammingSchoolService header={header} items={items} />
                <p>
                    オンライン・オフラインに対応したプログラミングスクールを運営しています。<br />
                    未経験の状態から状態から、プログラミングを行う上でのPCの操作から始まり、実務で利用するレベルのプログラミング技術までをきちんと学ぶため、
                    卒業まで講師が課題管理・スケジュール管理まで行います。
                </p>
            </Row>
        </Container>
    </PageTemplate>
)

export default ProgrammingSchool

const ProgrammingSchoolService = (props) => (
    <StaticQuery
        query={graphql`
      query ProgrammingService {
        file(relativePath: {eq: "becrazy.png"}) {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
        render={data => (
            <Service data={data} {...props} />
        )}
    />
)
